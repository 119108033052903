var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Lib
import * as React from "react";
import { RefinementList } from "react-instantsearch-dom";
import API from "../../forms/lib/API";
var TaxonomySelection = /** @class */ (function (_super) {
    __extends(TaxonomySelection, _super);
    function TaxonomySelection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            options: [],
            selectedOptions: [],
        };
        _this.setPreSelected = function () {
            if (_this.props.preSelected === null)
                return;
            var selected = _this.state.options.filter(function (option) {
                return _this.props.preSelected.indexOf("".concat(option.value)) !== -1;
            });
            _this.setState({ selectedOptions: selected });
        };
        _this.isChecked = function (value) {
            var selected = __spreadArray([], _this.state.selectedOptions, true);
            return !!selected.filter(function (selectedOption) { return "".concat(selectedOption.value) === "".concat(value); }).length;
        };
        _this.triggerCheckboxChange = function (ev) {
            if (ev.currentTarget.checked) {
                _this.addSelected(ev.currentTarget.value);
            }
            else {
                _this.removeSelected(ev.currentTarget.value);
            }
        };
        _this.toggleAllSelected = function () {
            if (_this.state.selectedOptions.length === _this.state.options.length) {
                _this.updateState([]);
            }
            else {
                _this.updateState(_this.state.options);
            }
        };
        _this.addSelected = function (value) {
            var selectedOption = _this.state.options.filter(function (option) { return "".concat(option.value) === "".concat(value); });
            if (selectedOption.length) {
                _this.updateState(__spreadArray(__spreadArray([], _this.state.selectedOptions, true), [selectedOption[0]], false));
            }
        };
        _this.removeSelectedByName = function (value) {
            _this.updateState(__spreadArray([], _this.state.selectedOptions, true).filter(function (option) { return "".concat(value) !== "".concat(option.name); }));
        };
        _this.removeSelected = function (value) {
            _this.updateState(__spreadArray([], _this.state.selectedOptions, true).filter(function (option) { return "".concat(value) !== "".concat(option.value); }));
        };
        _this.updateState = function (value) {
            _this.setState({ selectedOptions: value }, function () {
                var _a;
                var values = _this.state.selectedOptions.map(function (option) { return option.value; });
                (_a = _this.props) === null || _a === void 0 ? void 0 : _a.onSelected(_this.props.refinementSlug, values);
            });
        };
        _this.renderInput = function (option) {
            return (React.createElement("div", { className: "checkbox", key: "".concat(_this.props.taxonomySlug, "-").concat(option.value) },
                React.createElement("input", { id: "".concat(_this.props.idPrefix).concat(_this.props.taxonomySlug, "-").concat(option.value), className: "checkbox__control", value: option.value, type: "checkbox", checked: _this.isChecked(option.value), onChange: _this.triggerCheckboxChange }),
                React.createElement("label", { htmlFor: "".concat(_this.props.idPrefix).concat(_this.props.taxonomySlug, "-").concat(option.value), className: "checkbox__label" },
                    React.createElement("span", null, option.label))));
        };
        _this.render = function () {
            return (React.createElement("div", { className: "taxonomy-selection" }, !_this.state.options.length ? (React.createElement("div", { className: "loading" },
                React.createElement("svg", { className: "loading-animation loading__icon" },
                    React.createElement("use", { xlinkHref: "#icon--loading" })))) : (React.createElement(React.Fragment, null,
                React.createElement("header", { className: "taxonomy-selection__header" },
                    React.createElement("button", { type: "button", className: "link-btn", onClick: _this.toggleAllSelected }, "Select All"),
                    _this.props.introTitle && (React.createElement("h3", { className: "form__label" }, _this.props.introTitle)),
                    _this.props.introContent && (React.createElement("p", null, _this.props.introContent))),
                React.createElement("div", { className: "checkboxes checkboxes--inline" }, _this.state.options.map(_this.renderInput)),
                React.createElement("div", { style: { display: "none" } },
                    React.createElement(RefinementList, { attribute: _this.props.refinementSlug }))))));
        };
        return _this;
    }
    TaxonomySelection.prototype.componentDidMount = function () {
        var _this = this;
        if (typeof this.props.taxonomySlug !== 'undefined') {
            API.getTaxonomy(this.props.taxonomySlug)
                .then(function (resp) { return _this.setState({ options: resp }, function () { return _this.setPreSelected(); }); });
        }
    };
    return TaxonomySelection;
}(React.PureComponent));
export default TaxonomySelection;
