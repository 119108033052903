// Lib
import { getAPIBase } from "./getAPIBase";
var API = /** @class */ (function () {
    function API() {
    }
    API.getPosts = function () {
        return API._makeRequest('wp-json/wp/v2/posts', "GET", {}, null);
    };
    API.getRestaurants = function (inputValue) {
        return API._makeRequest("wp-json/custom/v1/restaurants-by-name?input=".concat(inputValue), "GET", {}, null);
    };
    API.getTaxonomy = function (taxonomy) {
        return API._makeRequest("wp-json/custom/v1/get-terms?taxonomy=".concat(taxonomy), "GET", {}, null);
    };
    API.getVenue = function (venueID) {
        return API._makeRequest("wp-json/custom/v1/venue/".concat(venueID), "GET", {}, null);
    };
    API.saveVenue = function (values) {
        return API._makeRequest("wp-json/custom/v1/venue/save", "POST", values, null);
    };
    API.suggestVenue = function (values) {
        return API._makeRequest("wp-json/custom/v1/venue/save", "POST", values, null);
    };
    API.saveReview = function (values) {
        return API._makeRequest("wp-json/custom/v1/review/save", "POST", values, null);
    };
    API.uploadImage = function (values) {
        var formData = new FormData();
        formData.append('file', values);
        return API._makeRequest("wp-json/custom/v1/image/upload", "POST", formData, { 'X-Requested-With': 'XMLHttpRequest' });
    };
    API.removeImage = function (id) {
        return API._makeRequest("wp-json/custom/v1/image/".concat(id, "/remove"), "DELETE", {}, null);
    };
    API.checkEmailExists = function (email) {
        return API._makeRequest("wp-json/custom/v1/email/check", "POST", { "email": email }, null);
    };
    API.checkUsernameExists = function (username) {
        return API._makeRequest("wp-json/custom/v1/username/check", "POST", { "username": username }, null);
    };
    API.getLatestReview = function (id, signal) {
        return fetch("".concat(API.API_BASE, "/wp-json/custom/v1/venue/").concat(id, "/latest-review"), {
            method: 'GET',
            redirect: 'error',
            mode: 'cors',
            credentials: 'same-origin',
            referrer: 'no-referrer',
            signal: signal,
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: null
        })
            .then(this._formatResponse)
            .catch(function (err) {
            return Promise.reject(err);
        });
    };
    API.searchPlaceFromGoogle = function (input) {
        return API._makeRequest("wp-json/google/v1/search", "POST", {
            "input": input,
            "sessionToken": window.googlePlacesSessionID || null
        }, null);
    };
    API.searchLocality = function (input) {
        return API._makeRequest("wp-json/google/v1/locality", "POST", {
            "input": input,
            "sessionToken": window.googlePlacesSessionID || null
        }, null);
    };
    API.getPlaceDetails = function (placeID) {
        var _a;
        return API._makeRequest("wp-json/google/v1/details", "POST", {
            "place_id": placeID,
            "admin_logged_in": !!((_a = window.wordpress_globals.admin_logged_in) !== null && _a !== void 0 ? _a : false),
            "sessionToken": window.googlePlacesSessionID || null
        }, null);
    };
    API.getVenuePlaceholder = function (placeID) {
        var _a;
        return API._makeRequest("wp-json/google/v1/details", "POST", {
            "place_id": placeID,
            "format": true,
            "admin_logged_in": !!((_a = window.wordpress_globals.admin_logged_in) !== null && _a !== void 0 ? _a : false),
            "sessionToken": window.googlePlacesSessionID || null
        }, null);
    };
    API.getGooglePhoto = function (photoID) {
        var url = "wp-admin/admin-ajax.php?photo_reference=".concat(photoID, "&action=get_google_photo");
        return API._makeRequest(url, "GET", {}, null);
    };
    API._makeRequest = function (url, type, data, headers) {
        data = data || {};
        headers = headers || {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        };
        return fetch("".concat(API.API_BASE, "/").concat(url), {
            method: type || 'GET',
            redirect: 'error',
            mode: 'cors',
            credentials: 'include',
            referrer: 'no-referrer',
            headers: headers,
            body: type !== 'GET'
                ? (data instanceof FormData ? data : JSON.stringify(data))
                : null,
        })
            .then(function (response) {
            if (response.ok) {
                var contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(function (json) {
                        return Promise.resolve(json);
                    });
                }
                else if (contentType && contentType.indexOf("image/jpeg") !== -1) {
                    return response.blob().then(function (json) {
                        return Promise.resolve(json);
                    });
                }
                else {
                    return response.text().then(function (text) {
                        return Promise.resolve(text);
                    });
                }
            }
            else {
                return response.json().then(function (json) {
                    return Promise.reject(json);
                });
            }
        })
            .catch(function (err) {
            return Promise.reject(err);
        });
    };
    API._formatResponse = function (response) {
        if (response.ok) {
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json().then(function (json) {
                    return Promise.resolve(json);
                });
            }
            else if (contentType && contentType.indexOf("image/jpeg") !== -1) {
                return response.blob().then(function (json) {
                    return Promise.resolve(json);
                });
            }
            else {
                return response.text().then(function (text) {
                    return Promise.resolve(text);
                });
            }
        }
        else {
            return response.json().then(function (json) {
                return Promise.reject(json);
            });
        }
    };
    API.API_BASE = getAPIBase(MODE);
    return API;
}());
export default API;
