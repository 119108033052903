var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// Lib
import React, { lazy, Suspense } from "react";
import { format, parse } from "date-fns";
import { unescapeString } from "../../search/lib/unescapeString";
// Components
var Rating = lazy(function () { return import('../../search/components/Rating'); });
//TS Interfaces
import { RatingIcons } from "../../search/interfaces/SearchPage";
var ReviewHit = /** @class */ (function (_super) {
    __extends(ReviewHit, _super);
    function ReviewHit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            expanded: false
        };
        _this.ratingDescriptions = [
            "OK",
            "Good",
            "Very Good",
            "Fabulous",
            "Exceptional",
        ];
        _this.getReviewerName = function (name, hideSurname) {
            if (!hideSurname)
                return name;
            var nameArr = name.split(' ');
            if (!nameArr.length) {
                return name;
            }
            if (nameArr.length === 1) {
                return nameArr[0];
            }
            nameArr[(nameArr.length - 1)] = nameArr[(nameArr.length - 1)].charAt(0);
            return nameArr.join(' ');
        };
        _this.render = function () {
            var hit = _this.props.hit;
            var date = parse(hit.date_visited, 'yyyyMMdd', new Date());
            var name = _this.getReviewerName(hit.user_name, hit.user_hide_surname);
            return (React.createElement("article", { className: _this.state.expanded ? "review review--expanded" : "review" },
                React.createElement("header", { className: "review__header" },
                    React.createElement("h3", { className: "h2" }, unescapeString(hit.post_title))),
                React.createElement("div", { className: "review__info" },
                    React.createElement(Suspense, { fallback: React.createElement("div", { className: "review__rating" }) },
                        React.createElement("div", { className: "review__rating" },
                            React.createElement(Rating, { number: hit.rating, icon: RatingIcons.Heart, fillRest: true }),
                            React.createElement("span", { className: "description" }, _this.ratingDescriptions[hit.rating - 1]))),
                    React.createElement("div", { className: "review__date" },
                        format(date, "dd.MM.yyyy"),
                        "\u00A0",
                        React.createElement("br", null),
                        React.createElement("span", { className: "date" },
                            "by\u00A0",
                            name))),
                React.createElement("ul", { className: "pill-list" }, hit.reviewer_allergy.map(function (el, idx) {
                    return React.createElement("li", { key: "review-".concat(hit.id, "-allergy-").concat(idx) },
                        React.createElement("span", { className: "pill" }, el));
                })),
                React.createElement("div", { className: "review__content", style: { height: _this.state.expanded ? 'auto' : '76px' } },
                    unescapeString(hit.post_content),
                    React.createElement("dl", { className: "review__extra" },
                        hit.menu_top_tips && (React.createElement("div", null,
                            React.createElement("dt", null,
                                React.createElement("h3", null, "Menu Top Tips")),
                            React.createElement("dd", null, unescapeString(hit.menu_top_tips)))),
                        hit.venue_top_tips && (React.createElement("div", null,
                            React.createElement("dt", null,
                                React.createElement("h3", null, "Venue Top Tips")),
                            React.createElement("dd", null, unescapeString(hit.venue_top_tips)))),
                        hit.recommendation && (React.createElement("div", null,
                            React.createElement("dt", null,
                                React.createElement("h3", null, "Recommended Dish")),
                            React.createElement("dd", null, unescapeString(hit.recommendation)))))),
                React.createElement("button", { className: "review__expand", type: "button", onClick: function () { return _this.setState({ expanded: !_this.state.expanded }); } },
                    React.createElement("span", null, _this.state.expanded ? 'collapse' : 'expand'))));
        };
        return _this;
    }
    return ReviewHit;
}(React.PureComponent));
export default ReviewHit;
