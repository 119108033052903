var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Lib
import React, { lazy, Suspense } from "react";
import { Configure, connectStats, Hits, InstantSearch, Pagination } from "react-instantsearch-dom";
// Components
import ReviewHit from "./components/ReviewHit";
import TaxonomySelection from "../search/components/TaxonomySelection";
import { checkboxesFallback } from "../forms/components/fallbacks/checkboxesFallback";
var ReviewTaxonomySelection = lazy(function () { return import('./components/TaxonomySelection'); });
// Config
import { TypeSenseConfig } from "../TypeSenseConfig";
// @ts-ignore
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
var typesenseInstantSearchAdapter = new TypesenseInstantSearchAdapter(TypeSenseConfig);
var searchClient = typesenseInstantSearchAdapter.searchClient;
var VenueReviews = /** @class */ (function (_super) {
    __extends(VenueReviews, _super);
    function VenueReviews() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            filterModalOpen: false,
            selectedAllergens: []
        };
        _this.taxonomySelection = React.createRef();
        _this.taxonomySelectionModal = React.createRef();
        _this.setSelectedAllergens = function (refinementLabel, values) {
            _this.setState({ selectedAllergens: values });
        };
        _this.removeSelected = function (value) {
            var _a, _b, _c, _d;
            (_b = (_a = _this.taxonomySelection) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.removeSelectedByName(value);
            (_d = (_c = _this.taxonomySelectionModal) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.removeSelectedByName(value);
        };
        _this.getSelectedAllergiesMarkup = function () {
            if (_this.state.selectedAllergens.length) {
                return (React.createElement("div", { className: "selected-allergens" },
                    React.createElement("span", null, "Filtering by:"),
                    _this.state.selectedAllergens.map(function (el) {
                        return React.createElement("span", { className: "pill" },
                            el,
                            React.createElement("button", { type: "button", className: "pill__remove", onClick: function () { return _this.removeSelected(el); } },
                                React.createElement("svg", null,
                                    React.createElement("use", { xlinkHref: "#icon--close" })),
                                React.createElement("span", null, "Remove")));
                    })));
            }
            return React.createElement(React.Fragment, null);
        };
        _this.render = function () {
            var formattedAllergens = _this.state.selectedAllergens.map(function (el) { return "reviewer_allergy:" + el; });
            var configuration = {
                hitsPerPage: 10,
                analytics: false,
                enablePersonalization: true,
                distinct: true,
                facetFilters: __spreadArray(__spreadArray([], formattedAllergens, true), ["venue_id:".concat(_this.props.venueID)], false)
            };
            var selectedAllergiesMarkup = _this.getSelectedAllergiesMarkup();
            var taxonomySkeleton = checkboxesFallback();
            var Stats = function (_a) {
                var nbHits = _a.nbHits;
                return (React.createElement("small", null,
                    nbHits.toLocaleString(),
                    " ",
                    nbHits === 1 ? 'review' : 'reviews'));
            };
            var CustomStats = connectStats(Stats);
            var Pagi = function (_a) {
                var nbHits = _a.nbHits;
                return ((nbHits > 0) ? React.createElement(Pagination, { defaultRefinement: 1, showFirst: false, showLast: false }) : React.createElement(React.Fragment, null));
            };
            var CustomPagi = connectStats(Pagi);
            return (React.createElement(InstantSearch, { indexName: "wp_review", searchClient: searchClient },
                React.createElement(Configure, __assign({}, configuration)),
                React.createElement("section", { className: "reviews-list" },
                    React.createElement("header", { className: "reviews-list__header" },
                        React.createElement("h3", { className: "h1" },
                            "Reviews ",
                            React.createElement(CustomStats, null)),
                        React.createElement("button", { className: "btn btn--filter", onClick: function () { return _this.setState({ filterModalOpen: true }); } },
                            "Filter",
                            React.createElement("svg", { className: "btn__icon" },
                                React.createElement("use", { xlinkHref: "#icon--filter" })))),
                    React.createElement("div", { className: "reviews-list__desktop" },
                        React.createElement(Suspense, { fallback: taxonomySkeleton },
                            React.createElement(ReviewTaxonomySelection, { taxonomySlug: "kitchen-free-from", refinementSlug: "reviewer_allergy", preSelected: [], introTitle: "Filter reviews by allergens", onSelected: _this.setSelectedAllergens, ref: _this.taxonomySelection }))),
                    selectedAllergiesMarkup,
                    React.createElement(Hits, { hitComponent: ReviewHit })),
                React.createElement(CustomPagi, null),
                React.createElement("div", { id: "allergen-overlay", className: "filter-overlay", style: {
                        display: _this.state.filterModalOpen ? 'block' : 'none'
                    } },
                    React.createElement("div", { className: "filter-overlay__inner" },
                        React.createElement("nav", { id: "site-nav", className: "site-nav" },
                            React.createElement("div", { className: "container" },
                                React.createElement("div", { className: "site-nav__inner" },
                                    React.createElement("div", { className: "site-nav__left" },
                                        React.createElement("button", { type: "button", className: "back-btn", onClick: function () { return _this.setState({ filterModalOpen: false }); } },
                                            React.createElement("span", null, "Back"),
                                            React.createElement("svg", null,
                                                React.createElement("use", { xlinkHref: "#icon--back" })))),
                                    React.createElement("div", { className: "site-nav__center" },
                                        React.createElement("h2", null, "Filters")),
                                    React.createElement("div", { className: "site-nav__right" })))),
                        React.createElement(Suspense, { fallback: taxonomySkeleton },
                            React.createElement(TaxonomySelection, { idPrefix: 'modal-', taxonomySlug: "kitchen-free-from", refinementSlug: "reviewer_allergy", preSelected: [], introTitle: "Filter reviews by allergens", introContent: "Select any of the allergens below to filter reviews", onSelected: _this.setSelectedAllergens, ref: _this.taxonomySelectionModal })),
                        React.createElement("div", { className: "filter-overlay__actions" },
                            React.createElement("div", { className: "container" },
                                React.createElement("button", { type: "button", className: "btn", onClick: function () { return _this.setState({ filterModalOpen: false }); } }, "View")))))));
        };
        return _this;
    }
    return VenueReviews;
}(React.Component));
export default VenueReviews;
