export var getAPIBase = function (mode) {
    if (process.env.API_HOST) {
        return process.env.API_HOST;
    }
    switch (mode) {
        case "production":
            return "https://www.allergycompanions.com";
        case "staging":
            return 'http://staging.allergy.class.sh';
        case "development":
        default:
            return 'http://allergy.local';
    }
};
