let apiKey, host, protocol, port, path;

switch(MODE){
    case "production":
        apiKey      = process.env.TYPESENSE_API_SEARCH_KEY || '';
        host        = process.env.TYPESENSE_HOST || 'localhost';
        protocol    = process.env.TYPESENSE_PROTOCOL || 'https';
        port        = '443';
        path        = process.env.TYPESENSE_PATH || '/search-reviews';
        break;
    case "staging":
        apiKey      = process.env.STAGING_TYPESENSE_API_SEARCH_KEY || '';
        host        = process.env.STAGING_TYPESENSE_HOST || 'localhost';
        protocol    = process.env.STAGING_TYPESENSE_PROTOCOL || 'http';
        port        = process.env.STAGING_TYPESENSE_PORT || '8108';
        path        = process.env.TYPESENSE_PATH || '';
        break;
    case "development":
    default:
        apiKey      = process.env.LOCAL_TYPESENSE_API_SEARCH_KEY || 'xyz';
        host        = process.env.LOCAL_TYPESENSE_HOST || 'localhost';
        protocol    = process.env.LOCAL_TYPESENSE_PROTOCOL || 'http';
        port        = process.env.LOCAL_TYPESENSE_PORT || '8108';
        path        = process.env.TYPESENSE_PATH || '';
        break;
}

export const TypeSenseConfig = {
    server: {
        apiKey: apiKey, // Be sure to use the search-only-api-key
        nodes: [
            {
                host: host,
                port: port,
                protocol: protocol,
                path: path
            }
        ]
    },
    additionalSearchParameters: {
        queryBy: "post_title"
    }
};